import { useState } from 'react';
import { useLoaderData, useRevalidator } from 'react-router-dom';
import { PatientDetails, NewPatient } from '../components';
import { getPatientRibbon } from '../utils/API';

export default function Patient() {
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [patientHistory, setPatientHistory] = useState([]);
  const [showNewPatient, setShowNewPatient] = useState(false);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const { patients } = useLoaderData();
  const revalidator = useRevalidator();

  const getPatientHistory = async (id) => {
    setPatientHistory([]);
    try {
      const patientRibbon = await getPatientRibbon(id);
      setPatientHistory(patientRibbon);
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleSelectPatient = async (id) => {
    const patient = patients.find((patient) => patient.patientId === id);
    setSelectedPatient(patient);
    getPatientHistory(id);
    setShowNewPatient(false);
  };

  const filterPatients = (searchTerm) => {
    const filteredPatients = patients.filter((patient) => {
      const fullName = `${patient.patientFname} ${patient.patientLname}`;
      return fullName.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setFilteredPatients(filteredPatients);
  };

  const renderPatients = () => {
    const list = filteredPatients.length
      ? filteredPatients
      : patients || [];

    return list.map((patient, index) => (
      <span
        onClick={() => handleSelectPatient(patient.patientId)}
        key={index}
        className={
          selectedPatient &&
          selectedPatient.patientId === patient.patientId
            ? 'active'
            : null
        }
      >
        {patient.patientFname} {patient.patientLname}
      </span>
    ));

  };

  return (
    <div className="content">
      <div id="sidebar">
        <h1>Patients</h1>
        <nav>
          <div>
          <button className="cta" onClick={() => setShowNewPatient(true)}>
            Add New Patient
          </button>
          </div>
          <label htmlFor="patient-search-input" className="sr-only"></label>
          <input
            id="patient-search-input"
            aria-label="Search contacts"
            placeholder="Find a patient"
            type="search"
            name="patient-search-input"
            className="text"
            onChange={(event) => filterPatients(event.target.value)}
          />
          {renderPatients()}
        </nav>
      </div>

      {showNewPatient ? (
        <div className="details">
          <NewPatient hideNewPatient={() => setShowNewPatient(false)} updatePatientList={() => revalidator.revalidate()} />
        </div>
      ) : (
        <div className="details">
          {!selectedPatient || !patientHistory ? (
            <div className="robo-container">
              <h1 className="robot-message">
                Please Select a Patient from the Left.
              </h1>
            </div>
          ) : (
            <PatientDetails
              patient={selectedPatient}
              history={patientHistory}
              getUpdatedPatientHistory={getPatientHistory}
            />
          )}
        </div>
      )}
    </div>
  );
}
