import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { AuthContext, useLoading, emailRegex } from '../utils';
import { Loader, Footer } from '../components';

export default function Signup() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState(''); // TODO: add phone number validation
  const [email, setEmail] = useState('');
  const [userType, setUserType] = useState(null);
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSignup, setIsSignup] = useState(false);
  const { isLoading, setIsLoading } = useLoading();
  const navigate = useNavigate();
  const useAuth = () => useContext(AuthContext);
  const auth = useAuth();

const userTypeOptions = [
  { value: 1, label: 'Physical Therapy' },
  { value: 2, label: 'Occupational Therapy' },
  { value: 3, label: 'Family Medicine' },
  { value: 4, label: 'Pediatrics' },
  { value: 5, label: 'Geriatrics' },
];


  const updateEmail = email => {
    setEmail(email);
    if (emailRegex.test(email)) {
      setEmailIsValid(true);
      setErrorMessage('');
    } else {
      if (!passwordIsValid) {
        setErrorMessage('Please enter a valid email and password.');
      }
      setErrorMessage('Please enter a valid email.');
      setEmailIsValid(false);
    }
  };

  const updatePassword = password => {
    setPassword(password);
    if (password.length < 8 && isSignup) {
      setErrorMessage('Password must be at least 8 characters.');
      setPasswordIsValid(false);
    } else {
      setErrorMessage('');
      setPasswordIsValid(true);
    }
  };

  const handleLogIn = e => {
    e.preventDefault();
    setIsLoading(true);
    auth.login(email, password)
      .then(() => {
        setIsLoading(false);
        setEmail('');
        setPassword('');
        navigate('/calendar', { replace: true });
      })
      .catch(error => {
        setIsLoading(false);
        setEmail('');
        setPassword('');
        if (error.response && error.response.status === 401) {
          setErrorMessage('Invalid credentials, please try again.');
        } else {
          setErrorMessage('Error logging in, please try again.');
        }
      });
  };

  const handleSignUp = e => {
    e.preventDefault();
    setIsLoading(true);
    const usertype_id = userType.value;
    auth.signup({ firstName, lastName, phone, email, password, usertype_id})
      .then(() => {
        setIsLoading(false);
        setFirstName('');
        setLastName('');
        setPhone('');
        setEmail('');
        setUserType(null);
        navigate('/hello');
      })
      .catch(error => {
        setIsLoading(false);
        setFirstName('');
        setLastName('');
        setPhone('');
        setEmail('');
        setPassword('');
        setUserType(null);
        if (error.response && error.response.status === 409) {
          setErrorMessage('This email is associated with an account already. Please log in instead.');
        } else {
          setErrorMessage('Error signing up, please try again.');
        }
      });
  };

  const handleSelect = (e) => {
    setUserType(e);
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div id="signup">
          <div className="trill">
            ribb
            <ion-icon
              class="signup-logo"
              name="ribbon-sharp"
              size="large"
            ></ion-icon>
            n
          </div>
          <h2>Prioritize Patients, Not Paperwork.</h2>
          <h3>
            Our technology converts your patient vist into detailed SOAP notes
            in realtime. We streamline your notetaking workflow to also improve
            billing, compliance, and discharge processes. <br />
            <br />
            With Ribbon, you can focus more on what truly matters—providing
            quality care and spending meaningful time with your patients.
          </h3>
          <h2 className="desktop-only">
            <a className="link" href="https://calendly.com/shiv-ribbon" target="_blank" rel="noreferrer">
              Contact Sales for a Free 7 Day Full-Access Trial now!
            </a>
          </h2>
          <h2 className="mobile-only">
            Ribbon is not currently available on smartphones, please use a
            tablet or a computer.
          </h2>

          {isSignup ? (
            <>
              <form className="signup-form">
                <div>
                  <input
                    type="text"
                    className="text"
                    placeholder="First Name"
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                  />
                  <input
                    type="text"
                    className="text"
                    placeholder="Last Name"
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                  />
                </div>
                <div>
                  <input
                    type="email"
                    className="text"
                    placeholder="Email"
                    onChange={(e) => updateEmail(e.target.value)}
                    value={email}
                    pattern=".+@globex\.com"
                    required
                    autoComplete="username"
                  />
                  <input
                    type="password"
                    className="text"
                    placeholder="Password"
                    onChange={(e) => updatePassword(e.target.value)}
                    value={password}
                    autoComplete="current-password"
                  />
                  <Select
                    options={userTypeOptions}
                    value={userType}
                    onChange={handleSelect}
                    id="usertype"
                  />
                  <br />
                </div>
                <div>
                  <button
                    onClick={handleSignUp}
                    disabled={
                      !email ||
                      !passwordIsValid ||
                      !firstName ||
                      !lastName ||
                      !emailIsValid
                    }
                    className={
                      !email ||
                      !passwordIsValid ||
                      !firstName ||
                      !lastName ||
                      !emailIsValid
                        ? 'disabled'
                        : 'cta'
                    }
                    type="submit"
                  >
                    Sign Up
                  </button>
                </div>
              </form>
              <br />
              <i>
                Already have an account? &nbsp;
                <span className="link" onClick={() => setIsSignup(false)}>
                  Log In
                </span>
              </i>
            </>
          ) : (
            <>
              <form className="desktop-only">
                <input
                  type="email"
                  className="text"
                  placeholder="Email"
                  onChange={(e) => updateEmail(e.target.value)}
                  value={email}
                  autoComplete="username"
                />
                <input
                  type="password"
                  className="text"
                  placeholder="Password"
                  onChange={(e) => updatePassword(e.target.value)}
                  value={password}
                  autoComplete="current-password"
                />
                <button
                  onClick={handleLogIn}
                  disabled={!email || !passwordIsValid || !emailIsValid}
                  className={
                    !email || !passwordIsValid || !emailIsValid
                      ? 'disabled'
                      : 'cta'
                  }
                  type="submit"
                >
                  Log In
                </button>
              </form>
              <br />
              <i className="desktop-only">
                Don't have an account? &nbsp;
                <a className="link" href="https://calendly.com/shiv-ribbon" target="_blank" rel="noreferrer">
                  Contact Sales
                </a>
                <br />
                <i className="desktop-only">
                  Forgot Your Password? &nbsp;
                  <span className="link" onClick={() => navigate('/reset')}>
                    Reset It Here
                  </span>
                </i>
              </i>
            </>
          )}
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <br />
          <br />
          {!isSignup && <Footer />}
        </div>
      )}
    </>
  );
}
