import { useState } from 'react';
import { sendFeedback } from '../utils/API';
import { notifyError, notifySuccess } from '../utils';

export default function Feedback() {
  const [feedback, setFeedback] = useState('');

  const handleSubmit = async () => {
    try {
      await sendFeedback({feedback});
      setFeedback('');
      notifySuccess('Thank you for the feedback!');
    } catch (error) {
      notifyError('Error sending feedback');
      throw new Error(error);
    }
  };

  return (
    <div className="content">
      <div className="details">
        <div className="feedback">
          <div>
            <h1>Feedback</h1>
            <textarea
              placeholder="Enter Feedback..."
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
            ></textarea>
            <button
              onClick={handleSubmit}
              disabled={!feedback}
              className={!feedback ? 'disabled' : 'cta'}
            >
              Send Feedback
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
