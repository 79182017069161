import { useState, useEffect } from 'react';
import { endAppt } from '../../utils/API';
import { notifyError, notifySuccess } from '../../utils';

import createSpeechServicesPonyfill from 'web-speech-cognitive-services';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

// eslint-disable-next-line no-unused-vars
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';

import './SpeechToText.css';
import wave from './audio-wave.gif';
// import { disable } from 'mixpanel-browser';

const subscriptionKey = process.env.REACT_APP_AZURE_SPEECH_KEY;
const serviceRegion = 'eastus';

const { SpeechRecognition: AzureSpeechRecognition } =
  createSpeechServicesPonyfill({
    credentials: {
      region: serviceRegion,
      subscriptionKey: subscriptionKey,
    },
  });
SpeechRecognition.applyPolyfill(AzureSpeechRecognition);

const SpeechToText = ({ patient, callback, /* editNoteData, getDetailsCallback */ }) => {
  let {
    transcript,
    listening,
    resetTranscript,
    // browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const [text, setText] = useState('');

  // set speech transcript to be textarea value
  useEffect(() => {
    setText(transcript);
  }, [transcript]);

  // clear inputs and stop listening when patient changes
  useEffect(() => {
    setText('');
    resetTranscript();
    SpeechRecognition.abortListening();
  }, [patient, resetTranscript]);

  const startListening = () => {
    SpeechRecognition.startListening({
      continuous: true,
      language: 'en-US',
    });
  }

  const stopListening = () => {
    setText(transcript)
    SpeechRecognition.abortListening();
  }

  const saveNote = async () => {
    stopListening();

    const payload = {
      patientId: patient.patientId,
      triageBpm: '1',
      triageTemp: '1',
      triageDiastolic: '1',
      triageSystolic: '1',
      visitNote: text,
    };

    try {
      const soapNote = await endAppt(payload);
      notifySuccess('Patient note saved!');
      const callbackPayload = Object.assign(payload, {visitId: soapNote.visitId});
      if (callback) callback(callbackPayload);
      setText('');
      resetTranscript();
    } catch (error) {
      notifyError('Error saving patient note');
      throw new Error(error);
    }
  }

  const confirmEndAppt = () => {
    confirmAlert({
      title: 'Confirm End Patient Visit',
      message: 'Are you sure you want to end this patient visit?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => saveNote(),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const renderRecordingButtons = (isListening) => {
    return (
      <div className="col">
        {!isListening ? (
          <ion-icon
            size="large"
            name="mic-sharp"
            onClick={startListening}
          ></ion-icon>
        ) : (
          <>
            <img className="wave" src={wave} alt="audio wave" />
            <ion-icon
              size="large"
              name="mic-off-sharp"
              style={{ color: '#ef5d60' }}
              onClick={confirmEndAppt}
            ></ion-icon>
          </>
        )}
        {!isListening ? (
          <span>Click to Start Patient Visit</span>
        ) : (
          <span>Click to End Patient Visit</span>
        )}
      </div>
    );
  };

  const renderNotesPanel = () => {
    return (
      <div className="triage">
        <>
          <div className="voice-controls">
            {renderRecordingButtons(listening)}
          </div>
        </>
      </div>
    );
  };

  return (
    <>
      <br />
      {renderNotesPanel()}
    </>
  );
}

export default SpeechToText;
